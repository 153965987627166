import React from "react"
import step1 from "../../Images/ml-step1.png"
import step2 from "../../Images/ml-step2.png"
import step3 from "../../Images/ml-step3.png"
import step4 from "../../Images/ml-step4.png"
import illustration from "../../Images/ml-how-work3.png"
import medialinqButton from "../../Images/medialinq-button-1.png"

const HowItWorksSection = () => (
    <div className="container">
      <div className="section-header">
        <h2>Så fungerar det</h2>
        <p>
          Nätskribenter som arbetar utan betalvägg
          har länge efterlyst ett system med vilket
          små donationer (ner till 1 kr) ska kunna
          ges snabbt, enkelt och till låg kostnad för
          mottagaren. Vi har nu utvecklat ett
          sådant system, kallat MediaLinq.
        </p><p style={{marginTop: "0.5em"}}>
        Betalningarna sker
        inom ramen för medlemskap i
        MediaLinq, vilket förbinder donatorer
        och mottagare. Deltagande skribenter
        installerar en ”donationsknapp” i
        anslutning till sina artiklar;
        Knappen eller länken är vanligen placerad direkt efter artikeln så läsaren snabbt kan avgöra om den var värd en
        "mikro"-donation.
        Även så små donationer som 1 kr kan göras lika effektivt som större. Se knappen som en gilla-knapp med
        belöningsfunktion (Gilla på riktigt!).
        T.ex. ger 100 donationer på 1 kr mottagaren lika mycket som 1 donation på 100 kr.
      </p><p style={{marginTop: "0.5em"}}>
        Det finns 4 typer av länkar och knappar, vilka alla har
        samma enkla donationsfunktion.
      </p>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="img-box">
            <img src={illustration} alt="Img"/>
          </div>
        </div>
        <div className="col-md-6">

          <div className="description-block">
            <div className="inner-box">
              <div className="step_num"><img src={step1} alt="Img"/></div>
              <p>Den enklaste är en ren länk som visas i klartext.<br/>
                <a
                  href="https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1">
                  https://www.medialinq.se/donera /index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1</a>
              </p>
            </div>
          </div>


          <div className="description-block">
            <div className="inner-box">
              <div className="step_num"><img src={step2} alt="Img"/></div>
              <p>Den andra typen är att man associerat en text till länken för att förklara funktionen.<br />
                <a
                  href="https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1">
                  Gilla på riktigt! Klicka här!
                </a>
              </p>
            </div>
          </div>


          <div className="description-block">
            <div className="inner-box">
              <div className="step_num"><img src={step3} alt="Img"/></div>
              <p>Den tredje typen är en grön knapp som är en bild med text associerad till samma länk som i fallen 1 och
                2.<br />
                  <a
                    href="https://www.medialinq.se/donera/index.html?r=c128b8a5-4616-4dd4-8897-b19dbde3fc11&s=referrer&a=1"><img
                    src={medialinqButton} style={{width :"350px"}} />
                  </a></p>
            </div>
          </div>


          <div className="description-block">
            <div className="inner-box">
              <div className="step_num"><img src={step4} alt="Img"/></div>
              <p>Den fjärde typen är en mer avancerad grön knapp med tre föreslagna donationsbelopp.<br/>
              </p>
              <div className="donera-container-small donera-bordered">
                <iframe className="donera_button" data-header="Gilla på riktigt!" data-extra="Donera 1 kr eller mer"
                        data-recipient="f26faaa8-9208-11ea-bb37-0242ac130002" data-amounts="1,5,10"
                        scrolling="no"></iframe>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
)

export default HowItWorksSection
