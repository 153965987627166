import React from "react"
import ResponsiveLayout from "../components/ResponsiveLayout"
import SliderSection from "../components/ResponsiveLayout/sliderSection"
import FeaturesSection from "../components/ResponsiveLayout/featuresSection"
import ScreenshotsSection from "../components/ResponsiveLayout/screenshotsSection"
import HowItWorksSection from "../components/ResponsiveLayout/howItWorksSection"
import ContactSection from "../components/ResponsiveLayout/contactSection"
import ResponsiveLayoutSection from "../components/ResponsiveLayout/responsiveLayoutSection"

const Index = (props) => {

  let menuPages = [
    { title: "Sidor",
      data:
        [
          { title: "Startsida", data: {href: "/index.html"} },
          { title: "Donator", data: {href: "/donator/index.html"} },
          { title: "Nudgewall", data: {href: "/nudgewall/index.html"} },
          { title: "Nätskribent", data: {href: "/mottagare/index.html"} },
          { title: "Vanliga frågor", data: {href: "/faqs/index.html"} }
        ]
    },
    { title: "Mikrodonationer", data: {"dataScrollNav": "3"}},
    { title: "Donationsknappen", data: {"dataScrollNav": "2"}},
    { title: "Så fungerar det", data: {"dataScrollNav": "4"}},
    { title: "Kontakt", data: {"dataScrollNav": "5"}}
  ]

  return (
    <ResponsiveLayout {...props} pages={menuPages}>
      <SliderSection />
      <ResponsiveLayoutSection dataScrollNav="3" sectionId="appScreenshots">
        <ScreenshotsSection />
      </ResponsiveLayoutSection>
      <ResponsiveLayoutSection dataScrollNav="2" sectionId="AppFeatures">
        <FeaturesSection />
      </ResponsiveLayoutSection>
      <ResponsiveLayoutSection dataScrollNav="4" sectionId="how-it-works">
        <HowItWorksSection />
      </ResponsiveLayoutSection>
      <ResponsiveLayoutSection dataScrollNav="5" sectionId="contact">
        <ContactSection />
      </ResponsiveLayoutSection>
  </ResponsiveLayout>
)
}

export default Index
