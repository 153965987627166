import React from "react"
import screen1 from "../../Images/screen/ml-1.png"
import screen2 from "../../Images/screen/ml-2.png"
import screen3 from "../../Images/screen/ml-3.png"

const ScreenshotsSection = () => (
  <section>
    <div className="shape-top"></div>
    <div className="container">
      <div className="section-header">
        <h2>För dig som skapar och publicerar på webben</h2>
        <p>
          Vi har introducerat mikrodonationssystemet MediaLinq med avsikt att väcka gratisläsarna till ökad
          betalningsvilja.
          Gamla skäl till ursäkter såsom tröghet att donera kan äntligen elimineras. Nu blir även allt från 1 kr är
          lönsamt för dig - om det sker ofta.
        </p><p style={{marginTop: "0.5em"}}>
        Att ge mikrobelopp har alla råd med och det kan nu ske på 5 sekunder tack vare vår nya teknik.
      </p>
      </div>

      <div className="list_screen_slide owl-carousel">
        
        <div className="item">
          <a href={screen1} data-rel="lightcase:gal">
            <img src={screen1} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen2} data-rel="lightcase:gal">
            <img src={screen2} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen3} data-rel="lightcase:gal">
            <img src={screen3} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen2} data-rel="lightcase:gal">
            <img src={screen2} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen3} data-rel="lightcase:gal">
            <img src={screen3} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen2} data-rel="lightcase:gal">
            <img src={screen2} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen3} data-rel="lightcase:gal">
            <img src={screen3} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen2} data-rel="lightcase:gal">
            <img src={screen2} alt="Img" />
          </a>
        </div>
        

        
        <div className="item">
          <a href={screen3} data-rel="lightcase:gal">
            <img src={screen3} alt="Img" />
          </a>
        </div>
        
      </div>
    </div>
  </section>
)

export default ScreenshotsSection
