import React from "react"
import banner from "../../Images/medialike-banner-home.png"

const SliderSection = () => (
  <section className="start_home demo2">
    <div className="banner_top">
      <div className="container">
        <div className="row">
          <div className="col-md-6 start-home-content">
            <h1>MediaLinq</h1>
            <h1 style={{fontSize: "30px"}}>Gilla på riktigt!</h1>
            <p>
              Välkommen att upptäcka möjligheterna hos det unika mikrodonationssystemet - MediaLinq. Man sätter in en
              mindre klumpsumma, exempelvis 100 kr, på ett donationskonto och sedan kan man börja gilla texter,
              artiklar, poddar och videos med en slant, exempelvis 5 kr, överallt där MediaLinq-knappen finns.

            </p>
          </div>
          <div className="col-md-6 start-home-img">
            <img className="img-fluid" src={banner} alt="Img"/>
          </div>
        </div>
      </div>
    </div>
    <div className="wave-area">
      <div className="wave"></div>
      <div className="wave"></div>
    </div>
  </section>
)

export default SliderSection
