import React from "react"
import img1 from "../../Images/icons/ml-1.png"
import img2 from "../../Images/icons/ml-2.png"
import img3 from "../../Images/icons/ml-3.png"
import img4 from "../../Images/icons/ml-4.png"
import imgSwish from "../../Images/icons/ml-swish.png"
import img6 from "../../Images/icons/ml-6.png"
import phone from "../../Images/medialinq-feature-phone.png"

const FeaturesSection = () => (
    <div className="container">
      <div className="section-header">
        <h2>Kort om Donationsknappen</h2>
        <p>
          Med MediaLinq gör du enkelt - med endast två klick - spontana och snabba donationer av små belopp till
          bloggare och artikelförfattare
          som uppskattning för ett bra arbete. Donationer kan ges till alla som har den gröna Donationsknappen eller en
          Donationslänk från
          MediaLinq på sin hemsida eller artikelsida. Du behöver inte använda mobilt BankID för att donera, utan du gör
          det direkt i din webbläsare.
        </p>
      </div>
      <div className="row">
        <div className="col-md-4">

          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={img1} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Visa uppskattning</h3>
              <p> Donera till dina favoritskribenter för att visa din uppskattning</p>
            </div>
          </div>

          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={img2} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Enkla donationer</h3>
              <p>Donera spontant och snabbt med endast två klick</p>
            </div>
          </div>
          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={img3} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Mikrodonationer</h3>
              <p>Små donationer, som 1 kr kan göras lika effektivt som större</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="img-box">
            <img src={phone} className="img-fluid" alt="Img"/>
          </div>
        </div>
        <div className="col-md-4">
          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={img4} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Den gröna knappen</h3>
              <p>Donationer kan ges till alla med den gröna Donationsknappen</p>
            </div>
          </div>
          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={imgSwish} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Swish och Bankgiro</h3>
              <p>Fyll på ditt donationskonto med Swish eller bankgiro</p>
            </div>
          </div>
          <div className="feature-block">
                            <span className="feature-icon">
                                <img src={img6} className="img-fluid" alt="Img"/>
                            </span>
            <div className="feature-content">
              <h3>Få betalt för din kreativitet</h3>
              <p>Med den gröna knappen kan du öka dina intäkter</p>
            </div>
          </div>
        </div>
      </div>
    </div>
)

export default FeaturesSection
